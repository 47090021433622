#phase {
  margin-top: 9.2rem;
  max-width: 28.4rem;
  @include minMobileLarge {
    max-width: 32.7rem;
  }
  @include minTablet {
    margin-top: 18rem;
    max-width: 66rem;
  }
  @include minDesktopSmall {
    max-width: 68.5rem;
    margin-top: 20rem;
  }
  @include minDesktopLarge {
    max-width: 111rem;
    display: flex;
    align-items: center;
  }

  .phase-left {
    @include minDesktopLarge {
      max-width: 46.5rem;
    }
    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
        text-align: center;
      }
      @include minDesktopLarge {
        text-align: left;
        font-size: 5.2rem;
        line-height: 6rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 3.2rem;
      font-weight: 300;
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.8rem;
        text-align: center;
        margin-top: 4.8rem;
      }
      @include minDesktopLarge {
        font-size: 1.8rem;
        line-height: 3rem;
        text-align: left;
        max-width: 42.5rem;
      }
    }
  }

  .phase-list {
    @include minDesktopLarge {
      margin-left: 10.8rem;
    }
    .phase {
      margin-top: 5.6rem;
      @include minTablet {
        margin-top: 7.2rem;
        display: flex;
        align-items: center;
      }
      @include minDesktopSmall {
        margin-top: 6.4rem;
      }

      .icon {
        min-width: 8rem;
        min-height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        box-shadow: 0 .9rem 2rem 0 rgba($silver, .2);
        border: .1rem solid $ghostWhite;
        border-radius: .72rem;
        @include minTablet {
          min-width: 10rem;
          min-height: 10rem;
        }

        img {
          height: 3rem;
          @include minTablet {
            height: 3.5rem;
          }
        }
      }

      &:first-of-type {
        @include minTablet {
          margin-top: 8rem;
        }
        @include minDesktopSmall {
          margin-top: 10rem;
        }
        @include minDesktopLarge {
          margin-top: 0;
        }
      }

      &:last-of-type {
        img {
          height: 4rem;
          @include minTablet {
            height: 4.7rem;
          }
        }
      }

      .phase-text {
        margin-top: 2rem;
        @include minTablet {
          margin-left: 6.4rem;
          margin-top: 0;
        }

        h3 {
          font-size: 2.4rem;
          line-height: 3rem;
          font-weight: 700;
          @include minDesktopSmall {
            font-size: 2.8rem;
            line-height: 2.8rem;
          }
        }

        p {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 1.2rem;
          font-weight: 300;
          @include minTablet {
            text-align: left;
            max-width: 44rem;
          }
          @include minDesktopSmall {
            font-size: 1.8rem;
            line-height: 3rem;
            margin-top: 1.6rem;
            max-width: 49.5rem;
          }
          @include minDesktopLarge {
            max-width: 37.3rem;
          }
        }
      }
    }
  }
}
