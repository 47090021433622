form {
  margin-top: 3.2rem;
  background: $white;
  border-radius: .8rem;
  display: flex;
  flex-direction: column;
  padding: .8rem;
  width: 100%;

  @include minMobileLarge {
    padding: 1.2rem 1.6rem;
    margin-top: 4rem;
  }

  @include minTablet {
    align-items: center;
    flex-direction: row;
    width: 35.4rem;
    padding: .8rem;
    margin-top: 3.2rem;
  }

  @include minDesktopSmall {
    margin-top: 6rem;
    width: 50rem;
  }

  @include minDesktopLarge {
    margin-top: 8rem;
  }

  .submit-email {
    border: 0;
    height: 4.8rem;
    width: 100%;
    margin-top: 1.6rem;
    font-size: 1.6rem;
    padding: 0 3rem;

    @include minTablet {
      margin-top: 0;
      margin-left: auto;
      width: initial;
      min-width: max-content;
    }
  }

  input {
    border: none;
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-left: .4rem;
    outline: none;
    width: 100%;
    color: $erieBlack;

    &::placeholder {
      color:$erieBlack;
      opacity: 1;
    }

    @include minMobileLarge {
      font-size: 1.6rem;
    }

    @include minTablet {
      line-height: 2.6rem;
      margin: 0 1.6rem;
      max-width:45%;
    }
    @include minDesktopSmall {
      max-width: 60%;
    }
  }
}
