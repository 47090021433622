@font-face {
  font-family: "Bartero";
  src: url("/fonts/Bartero.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8-dots1:before,
.icons8-dots2:before,
.icons8-dots3:before,
.icons8-dropdown:before,
.icons8-menu:before,
.icons8,
[data-icons8]:before {
  display: inline-block;
  font-family: "Bartero";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-dots1:before {content: "\f100";}
.icons8-dots2:before {content: "\f101";}
.icons8-dots3:before {content: "\f102";}
.icons8-dropdown:before {content: "\f103";}
.icons8-menu:before {content: "\f104";}


