#toast {
  transform: translate(-50%);
  position: fixed;
  z-index: 9999;
  top: 3.2rem;
  width: 100%;
  left: 50%;
}

.toast-notification {
  box-shadow: 0 1.2rem 2.4rem 0 rgba(0, 0, 0, .16);
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  transition: all $animTimingTwo;
  width: calc(100% - 3.2rem);
  border-radius: .4rem;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  display: flex;

  @include minTablet {
    padding-left: 2rem;
    margin-right: 2rem;
    width: 62.4rem;
  }

  & + .toast-notification {
    margin-top: 1.6rem;
  }

  .notification-text {
    color: $white;
    font-size: 1.4rem;
    line-height: 2rem;

    @include minTablet {
      line-height: 2.4rem;
      font-size: 1.8rem;
    }

  }

  .notification-icon {
    background: rgba(255, 255, 255, .3);
    height: 2.6rem;
    border-radius: 100rem;
    content: '';
    width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all $animTimingTwo;
    margin-left: auto;

    &:hover {
      background: rgba(255, 255, 255, .6);
    }
  }

  &.toast-success {
    background: $persianIndigo;
  }

  &.toast-error {
    background: $redPigment;
  }

}
