.referral-page {
  #splash {
    padding: 3.2rem 2.4rem;
    @include minTablet {
      padding: 3rem 4rem 4rem;
    }
    @include minDesktopSmall {
      padding: 2.7rem 4.8rem 4rem;
    }
    @include minDesktopLarge {
      padding:5.3rem 10rem;
    }
    h1 {
      max-width: 26rem;
      @include minMobileLarge {
        max-width: 24.4rem;
      }
      @include minTablet {
        margin-top: 0;
        max-width: 32.5rem;
        font-size: 4.8rem;
        line-height: 5.6rem;
        font-weight: 600;
      }
      @include minDesktopSmall {
        max-width: 39rem;
      }
      @include minDesktopLarge {
        font-size: 6rem;
        line-height: 6.8rem;
        max-width: 43rem;
      }
    }

    p {
      max-width: 26rem;
      font-weight: 300;
      @include minMobileLarge {
        max-width: 27.3rem;
      }
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.6rem;
        max-width: 27.6rem;
        margin-top: 3.2rem;
      }
      @include minDesktopSmall {
        max-width: 34.8rem;
        font-size: 1.8rem;
        line-height: 3rem;
      }
      @include minDesktopLarge {
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 47rem;
      }
    }

    .splash-image-1 {
      position: absolute;
      display: none;
      top: initial;
      left: initial;
      z-index: 1;
      @include minTablet {
        display: initial;
        height: 36.8rem;
        right: 2.7rem;
        bottom: 8rem;
      }
      @include minDesktopSmall {
        height: 50rem;
        bottom: 3.2rem;
      }
      @include minDesktopLarge {
        height: 58.8rem;
        right: 5.6rem;
        bottom: 3rem;
      }
    }

    .splash-image-2 {
      display: initial;
      position: absolute;
      top: 1.7rem;
      right: -2rem;
      height: 30rem;
      @include minMobileLarge {
        right: 0;
      }
      @include minTablet {
        display: none;
      }
    }
  }

  #steps-short {
    margin-top: 5.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 28rem;
    @include minMobileLarge {
      max-width: 33.8rem;
    }
    @include minTablet {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 67.5rem;
      margin-top: 6.4rem;
    }
    @include minDesktopSmall {
      max-width: 79rem;
      margin-top: 10rem;
    }
    @include minDesktopLarge {
      max-width: 117rem;
      margin-top: 12rem;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      max-width: 28.3rem;
      text-align: center;
      margin: 0 auto;
      @include minMobileLarge {
        max-width: 32.7rem;
      }
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
        max-width: 23rem;
        text-align: left;
      }
      @include minDesktopSmall {
        max-width: 33rem;
      }
      @include minDesktopLarge {
        font-size: 5.2rem;
        line-height: 6.8rem;
        max-width: 60rem;
        text-align: center;
      }
    }

    .card {
      padding: 3rem 2.4rem;
      min-height: 30rem;
      background: $white;
      border: .1rem solid $ghostWhite;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      margin-top: 2.4rem;
      border-radius: 2.1rem;
      width: 100%;
      max-width: 28rem;
      @include minMobileLarge {
        max-width: 32.6rem;
      }
      @include minTablet {
        max-width: calc((100% - 4.3rem) / 2);
        min-height: 33.5rem;
        margin-top: 4.3rem;
      }
      @include minDesktopSmall {
        margin-top: 4rem;
        padding: 4.5rem 2.6rem 0 3.8rem;
        max-width: calc((100% - 4rem) / 2);
        min-height: 38.6rem;
      }
      @include minDesktopLarge {
        max-width: calc((100% - 4.8rem) / 3);
      }

      &:first-of-type {
        padding: 0;
        border: 0;
        box-shadow: none;
        min-height: initial;
        @include minMobileLarge {
          max-width: 28rem;
        }
        @include minTablet {
          max-width: calc((100% - 4.3rem) / 2);
        }
        @include minDesktopLarge {
          max-width: 100%;
        }
      }

      &:nth-child(2) {
        margin-top: 5.6rem;
        @include minMobileLarge {
          margin-top: 4.8rem;
        }
        @include minDesktopLarge {
          margin-top: 10rem;
        }
      }

      h2 {
        @include minTablet {
          max-width: 25rem;
        }
        @include minDesktopLarge {
          max-width: 47rem;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 3.6rem;
        margin-top: 2rem;
        @include minTablet {
          line-height: 3rem;
          font-size: 2.4rem;
        }
        @include minDesktopSmall {
          margin-top: 2rem;
          font-size: 2.8rem;
          line-height: 4rem;
          font-weight: 700;
        }
      }

      p, li {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 300;
        margin-top: .8rem;
        @include minTablet {
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
        @include minDesktopSmall {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 2.3rem;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        max-width: 26.8rem;
        list-style-position: inside;
        @include minTablet {
          max-width: 30.7rem;
          margin-top: 1.6rem;
        }
        @include minDesktopSmall {
          max-width: 100%;
        }
      }

      li {
        text-align: left;
        margin-top: 1.2rem;
        margin-left: 1.4rem;
        @include minTablet {
          margin-top: 1.2rem;
        }
        @include minDesktopLarge {
          margin-left: 2rem;
        }

        &:before {
          content: "\2022"; /* \2022 is the CSS unicode for a bullet */
          color: $gains;
          font-size: 1.6rem;
          font-weight: bold;
          display: inline-block;
          width: 1.4rem;
          margin-left: -1.4rem;
          @include minTablet {
            font-size: 1.8rem;
          }
          @include minDesktopLarge {
            font-size: 2rem;
            width: 2rem;
            margin-left: -2rem;
          }
        }
      }
    }

    .step-profile {
      @include minTablet {
        margin-left: 4.3rem;
      }
      @include minDesktopSmall {
        margin-left: 4rem;
      }
      @include minDesktopLarge {
        margin-left: 0;
      }
    }

    .step-tasks {
      @include minTablet {
        order: 3;
        margin-left: 4.3rem;
      }
      @include minDesktopSmall {
        margin-left: 4rem;
      }
      @include minDesktopLarge {
        order: 2;
        margin-left: 2.4rem;
        margin-top: 10rem;
      }
    }

    .step-rewards {
      @include minTablet {
        order: 2;
      }
      @include minDesktopLarge {
        order: 3;
        margin-left: 2.4rem;
        margin-top: 10rem;
      }
    }

    .ro {
      min-height: 39rem;
      @include minTablet {
        min-height: 41rem;
      }
      @include minDesktopSmall {
        min-height: 42.5rem;
      }
      @include minDesktopLarge {
        min-height: 45.5rem;
      }
    }
  }

  #discover {
    @include minDesktopSmall {
      max-width: 85rem;
    }
    @include minDesktopLarge {
      max-width: 110rem;
    }

    .text {
      margin-top: 4.8rem;
      @include minTablet {
        margin-top: 0;
      }
      @include minDesktopLarge {
        margin-left: 19rem;
      }

      h2 {
        text-align: center;
        font-weight: 700;
        @include minTablet {
          text-align: left;
          max-width: 31.6rem;
        }
        @include minDesktopSmall {
          max-width: 37.5rem;
        }
        @include minDesktopLarge {
          max-width: 46rem;
        }
      }

      p {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 25rem;
        @include minMobileLarge {
          max-width: 32rem;
        }
        @include minTablet {
          text-align: left;
          max-width: 28rem;
          margin-left: 0;
        }
        @include minDesktopSmall {
          max-width: 37.5rem;
        }
        @include minDesktopLarge {
          max-width: 43.4rem;
        }
      }

      .register {
        display: none;
      }
    }
    img {
      @include minTablet {
        width: 33.5rem;
      }
      @include minDesktopSmall {
        width: 38rem;
      }
      @include minDesktopLarge {
        width: 45rem;
      }
    }
  }

  #about {
    margin-top: 10rem;
    @include minTablet {
      margin-top: 16rem;
    }

    .maxWidth {
      flex-direction: column-reverse;
      max-width: 28.4rem;
      @include minMobileLarge {
        max-width: 32.7rem;
      }
      @include minTablet {
        flex-direction: row-reverse;
        max-width: 72rem;
      }
      @include minDesktopSmall {
        max-width: 82rem;
      }
      @include minDesktopLarge {
        max-width: 111.8rem;
      }

      .text {
        margin-top: 4.8rem;
        @include minTablet {
          margin-top: 0;
          min-width: 34.5rem;
          margin-left: 4.2rem;
          margin-right: 0;
        }
        @include minDesktopSmall {
          min-width: 39rem;
          margin-left: 7rem;
        }
        @include minDesktopLarge {
          min-width: 50.5rem;
          margin-left: 17rem;
        }

        h2 {
          text-align: left;
          @include minTablet {
            max-width: 34.5rem;
          }
          @include minDesktopSmall {
            max-width: 39rem;
          }
          @include minDesktopLarge {
            max-width: 50.5rem;
          }
        }
        p {
          text-align: left;
          @include minTablet {
            max-width: 31.6rem;
          }
          @include minDesktopSmall {
            max-width: 39rem;
          }
          @include minDesktopLarge {
            max-width: 52rem;
            margin-top: 3.8rem;
          }
        }
        .about-paragraphs {
          @include minDesktopSmall {
            padding:0;
            border:none;
          }
        }
      }

      img {
        margin-top: 0;
        @include minTablet {
          height: 37rem;
        }
        @include minDesktopSmall {
          height: 41rem;
        }
        @include minDesktopLarge {
          height: 48rem;
        }
      }
    }
  }

  #how-it-works {
    margin-top: 6.4rem;
    @include minTablet {
      flex-direction: column;
      margin-top: 10rem;
    }
    .how-left {
      @include minTablet {
        max-width: 100%;
      }
      h2 {
        text-align: center;
        margin: 0 auto;
        max-width: 26rem;
        @include minTablet {
          max-width: 43rem;
        }
        @include minDesktopSmall {
          max-width: 54rem;
        }
      }
    }
    .how-right {
      @include minTablet {
        margin-top: 9rem;
        margin-left: 0;
        max-width: 69.6rem;
        flex-direction: row;
        flex-wrap: wrap;
      }
      @include minDesktopSmall {
        max-width: 78.2rem;
      }
      @include minDesktopLarge {
        max-width: 92.7rem;
      }
      .how-step {
        max-width: 100%;
        min-height: 12rem;
        @include minMobileLarge {
          padding:2.4rem 2rem;
        }
        @include minTablet {
          max-width: calc((100% - 1.6rem)/2);
          min-height: 16.8rem;
          margin-right: 1.6rem;
          margin-top: 0;
          margin-bottom:1.6rem;
          padding:3.4rem 2.8rem;
        }
        @include minDesktopSmall {
          max-width: calc((100% - 3.8rem)/2);
          margin-right:3.8rem;
          margin-bottom: 3.8rem;
        }
        @include minDesktopLarge {
          max-width: calc((100% - 6.4rem)/2);
          margin-right:6.4rem;
          margin-bottom: 6.4rem;
          padding:5.2rem;
        }
        &:nth-child(2n) {
          @include minMobileLarge {
            margin-left:initial;
          }
          @include minTablet {
            margin-right: 0;
          }
        }
        &:nth-child(3) {
          @include minTablet {
            margin-left: 0;
          }
        }
      }
    }
  }
}
