.card-page {
  #splash {
    padding:1.4rem 1.4rem 2rem;
    @include minMobileLarge {
      padding:1.4rem 2.4rem 2.4rem;
    }
    @include minTablet {
      display: flex;
      padding:2.4rem 2.4rem 6.7rem;
    }
    @include minDesktopSmall {
      padding:3.2rem 3.2rem 3.2rem 4.8rem;
    }
    @include minDesktopLarge {
      padding:7.2rem 6.6rem 8rem 9.8rem;
    }
    .card-image {
      width: 100%;
      display: block;
      margin-left: 1.5rem;
      @include minTablet {
        width: auto;
        height: 30rem;
        margin-left: -1.5rem;
      }
      @include minDesktopSmall {
        height: 37rem;
        margin-left: -2rem;
      }
      @include minDesktopLarge {
        height: 40rem;
        margin-top: -4rem;
      }
    }
    h1 {
      @include minTablet {
        max-width: 28rem;
      }
      @include minDesktopSmall {
        max-width: 46rem;
        margin-top:1.2rem;
      }
      @include minDesktopLarge {
        max-width: 52rem;
        margin-top: 2rem;
      }
    }
    p {
      @include minTablet {
        margin-top: 2.4rem;
        max-width: 34rem;
      }
      @include minDesktopSmall {
        max-width: 41.5rem;
      }
      @include minDesktopLarge {
        max-width: 46.6rem;
      }
    }
    form {
      @include minDesktopSmall {
        width: 45rem;
      }
      @include minDesktopLarge {
        width: 50rem;
      }
    }
    .splash-benefits {
      margin-top: 3.2rem;
      padding:3.2rem 1.6rem;
      border-radius: .7rem;
      background: $jet;
      width: 100%;
      @include minMobileLarge {
        padding:3.2rem 2.8rem;
      }
      @include minTablet {
        max-width: 30rem;
        margin-left: auto;
        border-radius: 2rem;
        margin-top: 0;
      }
      @include minDesktopSmall {
        max-width: 39rem;
        padding:3.2rem 3.2rem 3.2rem 4rem;
      }
      @include minDesktopLarge {
        padding:4rem 5.6rem;
        max-width: 48rem;
      }
      h2 {
        font-size: 3.2rem;
        line-height: 4rem;
        max-width: 16rem;
        font-weight: 600;
        @include minTablet {
          font-size: 4.8rem;
          line-height: 5.6rem;
          max-width: 23rem;
        }
        @include minDesktopSmall {
          font-size: 5.2rem;
          line-height: 6rem;
        }
      }
      .benefits-list {
        margin-top:5.6rem;
        list-style-type: none;
        padding:0;
        @include minTablet {
          margin-top: 13rem;
        }
        @include minDesktopLarge {
          margin-top: 15rem;
        }
        li {
          margin-bottom: 2.4rem;
          font-size: 1.6rem;
          line-height: 2rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          @include minDesktopSmall {
            font-size: 2rem;
            line-height: 2.8rem;
          }
          @include minTablet {
            margin-bottom: 3.6rem;
          }
          @include minDesktopSmall {
            margin-bottom: 3.2rem;
          }
          img {
            height: 2rem;
            margin-left: auto;
            @include minDesktopSmall {
              height: 2.5rem;
            }
          }
        }
      }
    }
  }
  #cards {
    margin-top:6.4rem;
    margin-bottom: 0;
    @include minMobileLarge {
      margin-top: 7.2rem;
    }
    @include minTablet {
      margin-top: 10rem;
    }
    @include minDesktopLarge {
      margin-top: 12rem;
    }
    h2 {
      max-width: 24.7rem;
      margin:0 auto;
      @include minMobileLarge {
        max-width: 34rem;
      }
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
        max-width: 58.8rem;
        font-weight: 600;
      }
      @include minDesktopWide {
        font-size: 5.2rem;
        line-height: 6rem;
      }
    }
  }
  #discover {
    margin-top: 8rem;
    margin-bottom:10rem;
    @include minTablet {
      margin-top: 14rem;
      margin-bottom: 14rem;
    }
    @include minDesktopLarge {
      margin-top: 18rem;
      margin-bottom: 12rem;
    }
    @include minDesktopExtraWide {
      margin-bottom: 14rem;
    }
  }
}
