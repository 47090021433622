#splash {
  width: 100%;
  max-width: calc(100% - 2rem);
  margin: 2.4rem auto 0;
  background: $erieBlack;
  border-radius: 2.4rem;
  padding: 3.2rem 2.4rem 2.4rem;
  color: $white;
  position: relative;
  overflow: hidden;
  @include minMobileLarge {
    max-width: calc(100% - 2.4rem);
  }
  @include minTablet {
    margin-top: 4.6rem;
    padding:4.4rem 3.8rem 5.4rem;
    max-width: calc(100% - 4rem);
  }
  @include minDesktopSmall {
    margin-top:5.8rem;
    padding:6.4rem 4.8rem 8rem;
    max-width: calc(100% - 6.4rem);
  }
  @include minDesktopLarge {
    max-width: 123.6rem;
    padding:6.8rem 10rem 9rem;
  }

  .text {
    z-index: 5;
    position: relative;
    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 500;
      margin: 0;
      @include minTablet {
        max-width: 47.2rem;
        font-size: 4.8rem;
        font-weight: 600;
        line-height: 5.6rem;
      }
      @include minDesktopLarge {
        font-size: 6rem;
        line-height: 6.8rem;
        max-width: 48.4rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 300;
      margin-top: 2.4rem;
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.6rem;
        max-width: 29rem;
        margin-top: 4rem;
      }
      @include minDesktopSmall {
        margin-top: 4.8rem;
        max-width: 40.5rem;
        font-size: 1.8rem;
        line-height: 3rem;
      }
      @include minDesktopLarge {
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 44.5rem;
      }
    }

    .register {
      margin-top: 3.2rem;
      width: 100%;
      @include minTablet {
        margin-top: 2.2rem;
        width: max-content;
      }
      @include minDesktopSmall {
        margin-top:4.8rem;
      }
    }
  }

  .splash-image-1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 38rem;
    z-index: 1;
    @include minTablet {
      height: 58.4rem;
      left: auto;
      right:0;
    }
    @include minDesktopSmall {
      height: 72rem;
    }
    @include minDesktopLarge {
      top:-6rem;
    }
  }

  .splash-image-2 {
    display: none;
    @include minTablet {
      display: block;
      z-index: 2;
      position: absolute;
      width: auto;
      height: 38rem;
      bottom:0;
      right:-3rem;
    }
    @include minDesktopSmall {
      height: 42rem;
      right:0;
    }
    @include minDesktopLarge {
      height: 59rem;
      bottom:-2rem;
      right:-4rem;
    }
  }
}
