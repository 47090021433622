#footer {
  background: $erieBlack;
  display: flex;
  width: 100%;

  .maxWidth {
    display: flex;
    flex-direction: column;
    padding-top: 5.6rem;
    padding-bottom: 4rem;
    @include minTablet {
      padding-bottom: 5.6rem;
    }
    @include minDesktopSmall {
      flex-direction: row;
      padding: 10rem 0;
    }
  }

  .left {
    p {
      color: $white;
      font-weight: 400;
      opacity: 60%;
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-top: 1.8rem;

      @include minDesktopSmall {
        font-size: 1.6rem;
        line-height: 2.6rem;

      }
      @include minDesktopLarge {
        font-size: 1.8rem;
        line-height: 2.9rem;

      }
    }

    .logo-footer {
      height: 6rem;
    }
  }

  .right {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 5.4rem;
    @include minTablet {
      flex-direction: row;
      justify-content: space-between;
    }
    @include minDesktopSmall {
      margin-left: auto;
      justify-content: initial;
      margin-top: 0;
    }

    .footer-menu {
      margin-top: 3.2rem;
      @include minTablet {
        margin-top: 0;
      }
      @include minDesktopSmall {
        margin-left: 7.8rem;
      }
      @include minDesktopLarge {
        margin-left: 10rem;
      }

      &:first-of-type {
        margin-top: 0;
        margin-left: 0;

      }

      h4 {
        color: $white;
        font-size: 2.6rem;
        line-height: 4rem;
        @include minDesktopLarge {
          font-size: 3rem;
          line-height: 4.9rem;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        color: $white;
        @include minTablet {
          margin-top: 1.3rem;
        }

        li {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.6rem;

          @include minDesktopLarge {
            font-size: 1.8rem;
            line-height: 2.9rem;
          }

          &:first-of-type {
            margin-top: 0;
          }

          a {
            text-decoration: none;
            color: $white;

            &:hover {
              text-decoration: underline;
            }

          }

        }
      }
    }

    .footer-ro {
      @include onlyDesktopSmall {
        margin-left: 4.8rem;
      }
    }
  }

}
