#success-splash {
  background: $erieBlack;
  padding:3.2rem 1.3rem;
  border-radius:2.4rem;
  color:$white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.6rem;
  @include minTablet {
    margin-top: 3.2rem;
    padding:4.8rem;
  }
  @include minDesktopSmall {
    padding:5.6rem 0;
  }
  @include minDesktopLarge {
    padding:8rem 0;
  }
  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: center;
    font-weight: 600;
    @include  minTablet {
      max-width: 63rem;
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
    @include minDesktopLarge {
      font-size: 6rem;
      line-height: 6.8rem;
      max-width: 84rem;
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-top: 2rem;
    text-align: center;
    font-weight:300;
    @include  minTablet {
      margin-top: 3.2rem;
      font-size: 1.6rem;
      line-height: 2.6rem;
      max-width: 47.2rem;
    }
    @include minDesktopSmall {
      font-size: 2rem;
      line-height: 3.2rem;
      max-width: 57.5rem;
      margin-top:4rem;
    }
  }
  .link {
    display: flex;
    flex-direction: column;
    margin-top: 4.8rem;
    align-items: center;
    width: 100%;
    @include minDesktopSmall {
      margin-top: 6.4rem;
    }
    #referral-link {
      font-size:2.4rem;
      line-height: 3.2rem;
      color:$white;
      text-align: center;
      max-width: 26.3rem;
      font-weight: 700;
      background: transparent;
      border:none;
      outline: none;
      @include minTablet {
        max-width: 100%;
        width: 100%;
        font-size: 2.8rem;
        line-height: 2.8rem;
        min-width: fit-content;
      }
      @include minDesktopSmall {
        font-size: 3.2rem;
        line-height: 3.2rem;
      }
    }
    #copy-btn {
      margin-top:1.5rem;
      font-size: 1.4rem;
      height: 4rem;
      min-width: 14rem;
      @include minTablet {
        height: 6rem;
        font-size: 2rem;
        margin-top: 3.2rem;
        min-width: 16rem;
      }
    }
  }
  .share {
    margin-top:4.8rem;
    @include minTablet {
      margin-top: 5.6rem;
    }
    @include minDesktopLarge {
      margin-top: 8rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 0;
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
      @include minDesktopSmall {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
    .socials {
      display: flex;
      justify-content: space-between;
      width: 21.3rem;
      margin-top: 2.4rem;
      @include minTablet {
        width: 28rem;
      }
      img {
        display: block;
        transition: all $animTimingTwo;
        cursor: pointer;
        &:hover {
          opacity: .7;
        }
        @include minTablet {
          height: 4rem;
        }
      }
    }
  }
}