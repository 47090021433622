.short-page {
  #splash {
    padding:1.6rem;
    @include minMobileLarge {
      padding:2rem 2.4rem;
    }
    @include minTablet {
      padding:2.8rem 4rem 6.3rem;
    }
    h1 {
      max-width: 26rem;
      margin-top: 16rem;
      @include minMobileLarge {
        max-width: 30.8rem;
      }
      @include minTablet {
        margin-top: 0;
        max-width: 48rem;
        font-size: 4.8rem;
        line-height: 5.6rem;
        font-weight: 600;
      }
      @include minDesktopSmall {
        max-width: 46.1rem;
      }
      @include minDesktopLarge {
        font-size: 5.2rem;
        line-height: 6rem;
        max-width: 52rem;
      }
    }

    p {
      max-width: 26rem;
      @include minMobileLarge {
        max-width: 30.8rem;
      }
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.6rem;
        max-width: 29rem;
        margin-top: 3.2rem;
      }
      @include minDesktopSmall {
        max-width: 40.5rem;
        font-size: 1.8rem;
        line-height: 3rem;
      }
      @include minDesktopLarge {
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 50rem;
      }
    }

    .splash-image-1 {
      position: absolute;
      display: none;
      top: initial;
      left: initial;
      z-index: 1;
      @include minTablet {
        display: initial;
        height: 40rem;
        right: -3rem;
        bottom: -3rem;
      }
      @include minDesktopSmall {
        height: 50rem;
        bottom: 0;
      }
      @include minDesktopLarge {
        height: 60rem;
        right: 0;
        bottom: -7rem;
      }
    }

    .splash-image-2 {
      display: initial;
      position: absolute;
      top: 1.7rem;
      right: -2rem;
      height: 30rem;
      @include minMobileLarge {
        right: 0;
      }
      @include minTablet {
        display: none;
      }
    }
  }

  #steps-short {
    margin-top: 5.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 28rem;
    @include minMobileLarge {
      max-width: 33.8rem;
    }
    @include minTablet {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 67.5rem;
      margin-top: 6.4rem;
    }
    @include minDesktopSmall {
      max-width: 79rem;
      margin-top: 10rem;
    }
    @include minDesktopLarge {
      max-width: 117rem;
      margin-top: 12rem;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      max-width: 28.3rem;
      text-align: center;
      margin: 0 auto;
      @include minMobileLarge {
        max-width: 32.7rem;
      }
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
        max-width: 23rem;
        text-align: left;
      }
      @include minDesktopSmall {
        max-width: 33rem;
      }
      @include minDesktopLarge {
        font-size: 5.2rem;
        line-height: 6.8rem;
        max-width: 60rem;
        text-align: center;
      }
    }

    .card {
      padding: 3rem 2.4rem;
      min-height: 30rem;
      background: $white;
      border: .1rem solid $ghostWhite;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      margin-top: 2.4rem;
      border-radius: 2.1rem;
      width: 100%;
      max-width: 28rem;
      @include minTablet {
        max-width: calc((100% - 4.3rem) / 2);
        min-height: 33.5rem;
        margin-top: 4.3rem;
      }
      @include minDesktopSmall {
        margin-top: 4rem;
        padding:4.5rem 2.6rem 0 3.8rem;
        max-width: calc((100% - 4rem) / 2);
        min-height: 36.5rem;
      }
      @include minDesktopLarge {
        max-width: calc((100% - 4.8rem) / 3);
      }

      &:first-of-type {
        padding: 0;
        border: 0;
        box-shadow: none;
        min-height: initial;
        @include minDesktopLarge {
          max-width: 100%;
        }
      }

      &:nth-child(2) {
        margin-top: 5.6rem;
        @include minMobileLarge {
          margin-top: 4.8rem;
        }
        @include minDesktopLarge {
          margin-top: 10rem;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 3.6rem;
        margin-top: 2rem;
        @include minTablet {
          line-height: 3rem;
          font-size: 2.4rem;
        }
        @include minDesktopSmall {
          margin-top: 2rem;
          font-size: 2.8rem;
          line-height: 4rem;
          font-weight: 700;
        }
      }

      p, li {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 300;
        margin-top: .8rem;
        @include minTablet {
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
        @include minDesktopSmall {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 2.3rem;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        max-width: 26.8rem;
        list-style-position: inside;
        @include minTablet {
          max-width: 30.7rem;
          margin-top: 1.6rem;
        }
        @include minDesktopSmall {
          max-width: 100%;
        }
      }

      li {
        text-align: left;
        margin-top: 1.2rem;
        margin-left: 1.4rem;
        @include minTablet {
          margin-top: 1.2rem;
        }
        @include minDesktopLarge {
          margin-left: 2rem;
        }

        &:before {
          content: "\2022"; /* \2022 is the CSS unicode for a bullet */
          color: $gains;
          font-size: 1.6rem;
          font-weight: bold;
          display: inline-block;
          width: 1.4rem;
          margin-left: -1.4rem;
          @include minTablet {
            font-size: 1.8rem;
          }
          @include minDesktopLarge {
            font-size: 2rem;
            width: 2rem;
            margin-left: -2rem;
          }
        }
      }
    }

    .step-profile {
      @include minTablet {
        margin-left: 4.3rem;
      }
      @include minDesktopSmall {
        margin-left: 4rem;
      }
      @include minDesktopLarge {
        margin-left: 0;
      }
    }

    .step-tasks {
      @include minTablet {
        order: 3;
        margin-left: 4.3rem;
      }
      @include minDesktopSmall {
        margin-left: 4rem;
      }
      @include minDesktopLarge {
        order:2;
        margin-left: 2.4rem;
        margin-top: 10rem;
      }
    }

    .step-rewards {
      @include minTablet {
        order: 2;
      }
      @include minDesktopLarge {
        order:3;
        margin-left: 2.4rem;
        margin-top: 10rem;
      }
    }

    .ro {
      min-height: 39rem;
      @include minTablet {
        min-height: 41rem;
      }
      @include minDesktopSmall {
        min-height: 42.5rem;
      }
      @include minDesktopLarge {
        min-height: 45.5rem;
      }
    }
  }
}
