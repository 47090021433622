#cards {
  margin: 9rem 0 4.4rem;
  color: $erieBlack;
  @include minMobileLarge {
    margin-top: 12rem;
  }
  @include minDesktopSmall {
    margin-top: 18rem;
    margin-bottom: 8rem;
  }
  @include minDesktopLarge {
    margin-top: 24rem;
  }

  .maxWidth {
    @include minDesktopWide {
      max-width: 115.6rem;
    }
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: center;
    @include minDesktopSmall {
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
  }

  .cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include minDesktopSmall {
      flex-direction: row;
      margin-top: 10rem;

    }
    @include minDesktopLarge {
      margin-top: 14rem;
    }

    .card {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 30rem;
      border-radius: 1.6rem;
      padding: 2rem 2.6rem 2.6rem;
      margin-bottom: 2rem;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      border: .1rem solid $ghostWhite;

      &:first-of-type {
        margin-top: 4.8rem;
        @include minDesktopSmall {
          margin-top: 0;
          margin-left: 0;
        }
      }

      @include minMobileLarge {
        max-width: 31rem;
      }
      @include minTablet {
        flex-direction: row;
        max-width: 61.6rem;
        padding: 3.2rem 4.4rem 3.2rem 6.5rem;
      }
      @include minDesktopSmall {
        flex-direction: column;
        margin-bottom: 0;
        margin-left: 1.6rem;
        max-width: 31rem;
        padding: 2rem 2.6rem 2.6rem;
      }
      @include minDesktopLarge {
        margin-left: 4rem;
        padding: 2.4rem 3.2rem 3.2rem;
        max-width: 34.5rem;
      }

      img {
        width: auto;
        height: 18rem;
        @include minDesktopLarge {
          height: 21rem;
        }
      }

      h3 {
        font-size: 2.4rem;
        line-height: 3rem;
        text-align: center;
        @include minDesktopLarge {
          font-size: 2.8rem;
          line-height: 3.6rem;
        }
      }

      .meta {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include minTablet {
          padding-left: 2.4rem;
          border-left: .1rem solid $ghostWhite;
          margin-left: 4.8rem;
          max-width: 29rem;
        }
        @include minDesktopSmall {
          padding-left: 0;
          border:none;
          margin-left: 0;
          max-width: initial;
        }
      }

      .description {
        font-size: 1.6rem;
        line-height: 2.6rem;
        max-width: 24.6rem;
        font-weight: 300;
        margin-top: 2.6rem;
        text-align: center;
        height: 11.6rem;
        @include minTablet {
          height: 9.7rem;
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
        @include minDesktopSmall {
          height: 11.6rem;
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
        @include minDesktopLarge {
          max-width: 28rem;
        }
      }

      .subscription, .price {
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 3rem;
        padding-top: 2.3rem;
        border-top: .1rem solid $ghostWhite;
        width: 100%;
        text-align: center;
        @include minTablet {
          display: none;
        }
        @include minDesktopSmall {
          display: initial;
        }
      }

      .price {
        padding-top: 0;
        border: 0;
        font-weight: 500;
        margin-top: 1.4rem;
      }

      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        .subscription,.price {
          display: none;
          border:none;
          padding:0;
          font-size: 1.6rem;
          line-height: 2.8rem;
          @include minTablet {
            display: initial;
          }
          @include minDesktopSmall {
            display: none;
          }
        }
        .price {
          @include minTablet {
            margin-top: .8rem;
          }
        }
      }

      .btn {
        margin-top: 3.2rem;
        width: 100%;
        line-height: 4.6rem;
        background: transparent;
        border: .1rem solid $silver;
        color: $erieBlack;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: .02rem;
        @include minTablet {
          max-width: 21rem;
        }
        @include minDesktopSmall {
          max-width: initial;
        }

        &:hover {
          background: $erieBlack;
          color: $white;
          border-color: transparent;
        }
      }
    }
  }
}
