#discover {
  margin-top: 7.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 .8rem;
  @include minMobileLarge {
    margin-top: 9.6rem;
  }
  @include minTablet {
    padding: 0;
    margin-top: 14rem;
    flex-direction: row;
  }
  @include minDesktopSmall {
    margin-top: 16rem;
  }
  @include minDesktopLarge {
    margin-top: 24rem;
  }

  img {
    width: 100%;
    height: auto;
    @include minTablet {
      max-width: 33.5rem;
    }
    @include minDesktopSmall {
      max-width: 40rem;
    }
    @include minDesktopLarge {
      max-width: 48.6rem;
    }
    @include minDesktopWide {
      max-width: 45rem;
    }
  }

  .text {
    margin-top: 3rem;
    color: $erieBlack;
    width: 100%;
    @include minTablet {
      margin-top: 0;
      margin-left: 6rem;
      max-width: 33.2rem;
    }
    @include minDesktopSmall {
      max-width: 48rem;
      margin-left:7.8rem;
    }
    @include minDesktopLarge {
      max-width: 54.2rem;
      margin-left:12.4rem;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 600;
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
      }
      @include minDesktopSmall {
        max-width: 100%;
      }
      @include minDesktopLarge {
        max-width: 51rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 3.2rem;
      font-weight: 300;
      @include minMobileLarge {
        margin-top: 2.4rem;
      }
      @include minTablet {
        margin-top: 4rem;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
      @include minDesktopSmall {
        margin-top: 4.8rem;
        font-size: 1.8rem;
        line-height: 3rem;
      }
      @include minDesktopLarge {
        max-width: 50rem;
        margin-top: 4rem;
      }
    }

    .btn {
      margin-top: 3.2rem;
      width: 100%;
      line-height: 4rem;
      @include minTablet {
        margin-top: 2.4rem;
        line-height: 4.8rem;
        width: max-content;
      }
      @include minDesktopSmall {
        margin-top: 4rem;
        line-height: 6rem;
        border-radius: .8rem;
      }
    }
  }
}
