html {
  height: 100%;
}

.page-error {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 3.2rem;
  @include minMobileLarge {
    margin-top: 4rem;
  }
  @include minTablet {
    margin-top:6.4rem;
  }
  @include minDesktopSmall {
    margin-top:4.8rem;
  }

  .bshape {
    position: absolute;
    z-index: -1;
    width: 43.5rem;
    top:-3.2rem;
    left:-14rem;
    @include minMobileLarge {
      top:-4rem;
      left:-17rem;
      width: 52.3rem;
    }
    @include minTablet {
      left:-28.2rem;
      top:-6.4rem;
      width: 93.4rem;
    }
    @include minDesktopSmall {
      width: 73.3rem;
      top:-4.8rem;
      left:3.2rem;
    }
    @include minDesktopLarge {
      left:17.4rem;
    }
    @include minDesktopWide {
      width: 91.8rem;
      left:31.3rem;
    }
    @include minDesktopExtraWide {
      width: 123rem;
      left:40rem;
    }
  }

  .logo {
    position:absolute;
    top:0;
    img {
      height: 4rem;
      width: auto;
      @include minTablet {
        height: 4.8rem;
      }
    }

  }

  #error-inner {
    align-items: center;
    flex-direction: column;
    display: flex;
    width: calc(100% - 7rem);
    h1,
    p {
      text-align: center;
    }

    h1 {
      font-size: 10rem;
      font-weight: 700;
      color: $erieBlack;
      @include minTablet {
        font-size: 14rem;
      }
    }

    p {
      margin-top:4.8rem;
      line-height: 2.6rem;
      font-size: 1.6rem;
      color:$erieBlack;
      font-weight: 600;
      @include minMobileLarge {
        max-width: 25rem;
      }
      @include minTablet {
        margin-top:6.5rem;
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 100%;
      }
      @include minDesktopLarge {
        margin-top: 6.4rem;
      }

      @include minDesktopWide {
        margin-bottom: 6rem;
        font-size: 2.8rem;
      }

    }

    .btn {
      min-width: 20rem;
      margin-top:4.8rem;
      font-size: 1.4rem;
      line-height: 4rem;
      @include minTablet {
        line-height: 4.8rem;
        font-size: 1.6rem;
      }
      @include minDesktopLarge {
        margin-top:6.4rem;
      }
    }

  }

}
