// Abstracts
@import "abstracts/variables";
@import "abstracts/mixins";

// Base
@import "base/normalize";
@import "base/animations";
@import "base/base";
@import "base/fonts";
@import "base/icons";

// Components
@import "components/container";
@import "components/header";
@import "components/splash";
@import "components/button";
@import "components/how-it-works";
@import "components/three-steps";
@import "components/discover";
@import "components/benefits";
@import "components/about";
@import "components/cards";
@import "components/footer";
@import "components/toast";
@import "components/error";
@import "components/short";
@import "components/legal";
@import "components/form";
@import "components/card";
@import "components/phase";
@import "components/subscribe";
@import "components/referral";
@import "components/success-splash";
@import "components/referral-stats";
@import "components/beta";
