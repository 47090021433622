#benefits {
  margin-top: 8rem;
  position: relative;
  @include minTablet {
    margin-top: 19rem;
  }
  @include minDesktopSmall {
    margin-top: 22.6rem;
  }
  @include minDesktopLarge {
    margin-top: 32rem;
  }

  .inner {
    position: relative;
    margin: 0 auto;
    @include minDesktopSmall {
      max-width: 86.2rem;
    }
    @include minDesktopLarge {
      max-width: 110rem;
    }
  }

  .benefits-bg {
    position: absolute;
    z-index: -1;
    display: none;
    @include minTablet {
      display: initial;
      top: 0;
      left: 0;
      height: 95rem;
    }
    @include minDesktopSmall {
      left: initial;
      height: initial;
    }
    @include minDesktopWide {
      right: 3.4rem;
      top: 10rem;
    }
    @include minDesktopExtraWide {
      right: 25rem;
    }
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4rem;
    max-width: 28.3rem;
    text-align: center;
    margin: 0 auto;
    @include minMobileLarge {
      max-width: 32.7rem;
    }
    @include minTablet {
      position: absolute;
      font-size: 4.8rem;
      line-height: 5.6rem;
      max-width: 39.2rem;
      text-align: left;
      left: 2rem;
      top: -3.7rem;
    }
    @include minDesktopSmall {
      left: -3.4rem;
      top: -2.7rem;
    }
    @include minDesktopLarge {
      left: 3rem;
      top: -8.4rem;
      font-size: 5.2rem;
      line-height: 6.8rem;
    }
  }

  .benefits-cards {
    display: flex;
    margin-top: 4.8rem;
    overflow-x: auto;
    padding: 0 1rem 3rem 5.5rem;
    @include minMobileLarge {
      padding-left: 0;
    }
    @include minTablet {
      margin-top: 0;
      padding-top: 1rem;
    }
    @include minDesktopSmall {
      padding-top: 0;
    }

    .mobile-container {
      display: flex;
      @include minMobileLarge {
        flex-direction: column;
        margin-right: .8rem;
      }
      @include minTablet {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
      }
    }

    .cards-col {
      display: flex;
      @include minMobileLarge {
        flex-direction: column;
      }
      @include minTablet {
        margin-right: 1rem;
      }
      @include minDesktopSmall {
        margin-right: 7.4rem;
      }
      @include minDesktopLarge {
        margin-right: 3.2rem;
      }
    }

    .col-max {
      @include minMobileLarge {
        margin-top: 6rem;
      }
      @include minTablet {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .card {
      padding: 2.4rem 1.6rem 2rem;
      border-radius: 2.1rem;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      border: .1rem solid $ghostWhite;
      background: $white;
      width: 17.2rem;
      margin-right: 1.2rem;
      position: relative;
      @include minMobileLarge {
        margin-right: 0;
        width: 100%;
        max-width: 17.2rem;
        min-height: 26rem;
        margin-bottom: .8rem;
      }
      @include minTablet {
        margin-bottom: 1rem;
        min-height: 30.6rem;
        max-width: 23.8rem;
        padding: 3rem 2.4rem;
      }
      @include minDesktopSmall {
        margin-bottom: 7.4rem;
      }
      @include minDesktopLarge {
        max-width: 34.5rem;
        padding: 4.4rem 4rem;
        min-height: 36rem;
        margin-bottom: 3.2rem;
      }

      &:nth-last-child(1) {
        @include minTablet {
          margin-bottom: 0;
        }
      }

      .icons8-dots1 {
        display: none;
        @include minTablet {
          display: initial;
          position: absolute;
          font-size: 3rem;
          top: -1.5rem;
          right: 1.5rem;
          opacity: .7;
        }
        @include minDesktopSmall {
          right:2rem;
          top:2rem;
        }
        @include minDesktopLarge {
          top:2.4rem;
          right:-1rem;
        }
      }

      .icons8-dots2 {
        display: none;
        @include minTablet {
          display: initial;
          position: absolute;
          font-size: 2rem;
          bottom:-1rem;
          right: 2.5rem;
          opacity: .7;
        }
        @include minDesktopSmall {
          right:initial;
          left:0;
        }
        @include minDesktopLarge {
          left:3rem;
        }
      }

      img {
        height: 3.4rem;
        @include minTablet {
          height: 4.4rem;
        }
        @include minDesktopLarge {
          height: 6rem;
        }
      }

      h3 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 3.6rem;
        @include minTablet {
          line-height: 3rem;
          font-size: 2.4rem;
          margin-top: 2rem;
        }
        @include minDesktopLarge {
          margin-top: 2.6rem;
          font-size: 2.8rem;
          line-height: 2.8rem;
          font-weight: 700;
        }
      }

      p {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 300;
        margin-top: .4rem;
        @include minTablet {
          margin-top: 1.2rem;
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
        @include minDesktopLarge {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 2.3rem;
        }
      }
    }
  }
}
