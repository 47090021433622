/*
 * Media query breakpoints
 */

$minMobileLargeWidth: 375px;
$minTabletWidth: 768px;
$minDesktopSmallWidth: 992px;
$minDesktopLargeWidth: 1366px;
$minDesktopWideWidth: 1800px;
$minDesktopExtraWideWidth: 2000px;

$maxMobileSmWidth: 359.98px;
$maxMobileWidth: 767.98px;
$maxTabletWidth: 991.98px;
$maxDesktopSmallWidth: 1365.98px;
$maxDesktopLargeWidth: 1659.98px;

/*
 * Container
 */

$containerPaddingMobile: 16px;
$containerPaddingTablet: 32px;
$containerPaddingDesktop: 32px;

/*
 * Fonts
 */

$poppins: poppins, sans-serif;

/*
 * Animations timing
 */

$animTimingOne: 0.1s ease-in-out;
$animTimingTwo: 0.2s ease-in-out;
$animTimingThree: 0.3s ease-in-out;
$animTimingFour: 0.4s ease-in-out;
$animTimingFive: 0.5s ease-in-out;

/*
 * Colors
 */

$maximumBlueGreen: #31cabb;
$antiFlashWhite: #f1f1f1;
$lightRedOchre: #ED7050;
$tiffanyBlue: #13bdad;
$lightSalmon: #ff9780;
$spanishGray: #9a9a9a;
$silverSand: #c3c3c3;
$isabelline: #eaeaea;
$pastelGray: #cbcbcb;
$isabelline: #efefef;
$seaSerpent: #49c4b8;
$someColor: #86d8d0;
$coralReef: #ff8064;
$aliceBlue: #edfaf9;
$codGray: #171717;
$black: #000;
$white: #fff;


/**
 * App colors
 */

$persianGreen: #08ae9d;
$aquaSpring: #E8F8F6;
$silver: #C3C0C0;
$arsenic: #444;


/** Bartero colors
*
 */

$spanishViolet: #513589;
$winterSky: #FF006A;
$persianIndigo: #2A0477;
$magnolia: #F5F1FF;
$azure: #EBFFFF;
$lavender: #EBE6F5;
$glossyGrape: #9B8CBA;
$royalPurple:#654A9D;
$malachite:#2BD968;
$redPigment:#F01227;


/** new colors */

$erieBlack:#232323;
$majorelle:#7145EA;
$ghostWhite:#F6F3FB;
$silver:#C6C6C6;
$gains:#DCE0E4;
$jet: #2F2F2F;