#referral-stats {
  margin-top: 6.4rem;
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include minTablet {
    margin-top: 8rem;
    margin-bottom: 10rem;
  }
  @include minDesktopSmall {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  @include minDesktopLarge {
    margin-bottom: 14rem;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: center;
    @include minTablet {
      font-size: 4.8rem;
      line-height: 5.6rem;
      max-width: 50rem;
    }
  }

  .stats-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    @include minMobileLarge {
      margin-top: 6.4rem;
    }
    @include minTablet {
      flex-direction: row;
      width: 100%;
      max-width: 67.2rem;
      margin-top: 8rem;
      justify-content: space-between;
      align-items: center;
    }
    @include minDesktopSmall {
      margin-top: 12rem;
      max-width: 83rem;
    }
    @include minDesktopLarge {
      max-width: 85rem;
    }

    .stats {
      padding: 3.2rem;
      background: $white;
      border-radius: 2.1rem;
      margin-bottom: 2.4rem;
      min-height: 24.5rem;
      border: .1rem solid $ghostWhite;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      @include minTablet {
        width: 100%;
        max-width: calc((100% - 4rem) / 2);
        margin-right: 4rem;
        margin-bottom: 0;
        min-height: 33.5rem;
        padding: 3.2rem 4rem;
      }
      @include minDesktopSmall {
        max-width: calc((100% - 8rem) / 2);
        margin-right: 8rem;
      }
      @include minDesktopLarge {
        max-width: calc((100% - 10rem) / 2);
        margin-right: 10rem;
      }

      img {
        @include minTablet {
          height: 8rem;
        }
      }

      &:nth-last-child(1) {
        margin: 0;
      }

      h3 {
        font-size: 2rem;
        line-height: 3.6rem;
        margin-top: 2rem;
        @include minTablet {
          font-size: 2.4rem;
          line-height: 3rem;
          margin-top: 3.2rem;
        }
        @include minDesktopSmall {
          font-size: 2.8rem;
          line-height: 2.8rem;
        }
      }

      p {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 300;
        max-width: 17rem;
        @include minTablet {
          margin-top: 2rem;
          font-size: 1.4rem;
          line-height: 2.4rem;
          max-width: 23rem;
        }
        @include minDesktopSmall {
          font-size: 1.6rem;
          line-height: 2.6rem;
          max-width: 31rem;
        }
      }
    }
  }
}