#beta {
  width: 100%;
  max-width: calc(100% - 2rem);
  margin: 2.8rem auto 6.4rem;
  background: $erieBlack;
  border-radius: 2.4rem;
  padding: 4.8rem 2rem;
  color: $white;
  position: relative;
  overflow: hidden;
  @include minMobileLarge {
    max-width: calc(100% - 2.4rem);
  }
  @include minTablet {
    margin-top: 4.6rem;
    padding: 8.8rem;
    max-width: calc(100% - 4rem);
  }
  @include minDesktopSmall {
    margin-top: 5.8rem;
    padding: 6.8rem 0;
    max-width: calc(100% - 6.4rem);
  }
  @include minDesktopLarge {
    max-width: 123.6rem;
    padding: 6.8rem 10rem 9rem;
  }

  .bshape {
    height: 33.5rem;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    @include minMobileLarge {
      height: 37rem;
    }
    @include minTablet {
      height: 53.6rem;
    }
    @include minDesktopSmall {
      height: 72.6rem;
    }
  }

  .text {
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      z-index: 10;
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 500;
      text-align: center;
      margin: 0;
      @include minTablet {
        font-size: 4.8rem;
        font-weight: 600;
        line-height: 5.6rem;
      }
      @include minDesktopSmall {
        font-size: 6rem;
        line-height: 6.8rem;
        max-width: 48.4rem;
      }
      @include minDesktopLarge {
        font-size: 6rem;
        line-height: 6.8rem;
        max-width: 48.4rem;
      }
    }

    p {
      z-index: 10;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 300;
      margin-top: 3.2rem;
      text-align: center;
      max-width: 22.3rem;
      @include minMobileLarge {
        margin-top: 4rem;
        max-width: 30.7rem;
      }
      @include minTablet {
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 44.5rem;
        margin-top: 6.4rem;
      }
      @include minDesktopSmall {
        margin-top: 4.8rem;
        max-width: 40.5rem;
        font-size: 1.8rem;
        line-height: 3rem;
      }
      @include minDesktopLarge {
        font-size: 2rem;
        line-height: 3.2rem;
        max-width: 44.5rem;
      }
    }

    .appstores {
      display: flex;
      align-items: center;
      z-index: 10;
      margin-top: 3.2rem;
      @include minMobileLarge {
        margin-top: 6rem;
      }
      @include minTablet {
        margin-top: 6.4rem;
      }
      @include minDesktopSmall {
        margin-top: 8.2rem;
      }

      img {
        height: 4.2rem;
        @include minMobileLarge {
          height: 4.6rem;
        }
        @include minTablet {
          height: 7.6rem;
        }

        &:first-of-type {
          margin-right: 2rem;
          @include minMobileLarge {
            margin-right: 3.2rem;
          }
          @include minTablet {
            margin-right: 6.4rem;
          }
        }
      }
    }
  }
}

