.container {
  width: 100%;
  padding: 0 1.4rem;
  @include minTablet {
    padding: 0 2rem;
  }
  @include minDesktopSmall {
    padding: 0 3.2rem;
  }
  @include minDesktopLarge {
    padding: 0 10rem;
  }
  @include minDesktopWide {
    padding: 0 37.7rem;
  }
  @include minDesktopExtraWide {
    padding: 0 69rem;
  }
}

.maxWidth {
  width: 100%;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  @include minMobileLarge {
    max-width: 34rem;
  }

  @include minTablet {
    max-width: 72.8rem;
  }

  @include minDesktopSmall {
    max-width: 96rem;
  }

  @include minDesktopLarge {
    max-width: 116.6rem;
  }

}