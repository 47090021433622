/*
 * Images
 */

@mixin backgroundImage($image) {
	background: url($image) center center no-repeat;
	background-size: cover;
}

/*
 * Media query
 */

@mixin mediaQueryMin($minWidth) {
	@media only screen and (min-width: $minWidth) {
		@content;
	}
}

@mixin mediaQueryMax($maxWidth) {
	@media only screen and (max-width: $maxWidth) {
		@content;
	}
}

@mixin mediaQueryBetween($minWidth, $maxWidth) {
	@media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
		@content;
	}
}

/*
 * Min
 */

@mixin minMobileLarge {
	@include mediaQueryMin($minMobileLargeWidth) {
		@content;
	}
}

@mixin minTablet {
	@include mediaQueryMin($minTabletWidth) {
		@content;
	}
}

@mixin minDesktopSmall {
	@include mediaQueryMin($minDesktopSmallWidth) {
		@content;
	}
}

@mixin minDesktopLarge {
	@include mediaQueryMin($minDesktopLargeWidth) {
		@content;
	}
}

@mixin minDesktopWide {
	@include mediaQueryMin($minDesktopWideWidth) {
		@content;
	}
}

@mixin minDesktopExtraWide {
  @include mediaQueryMin($minDesktopExtraWideWidth) {
    @content;
  }
}

/*
 * Max
 */

@mixin maxMobile {
	@include mediaQueryMax($maxMobileWidth) {
		@content;
	}
}

@mixin maxTablet {
	@include mediaQueryMax($maxTabletWidth) {
		@content;
	}
}

@mixin maxDesktopSmall {
	@include mediaQueryMax($maxDesktopSmallWidth) {
		@content;
	}
}

/*
 * Only
 */

@mixin onlyTablet {
	@include mediaQueryBetween($minTabletWidth, $maxTabletWidth) {
		@content;
	}
}

@mixin onlyDesktopSmall {
	@include mediaQueryBetween($minDesktopSmallWidth, $maxDesktopSmallWidth) {
		@content;
	}
}

/*
 * CSS common
 */

@mixin transform($property) {
	-webkit-transform: $property;
	-moz-transform: $property;
	-ms-transform: $property;
	-o-transform: $property;
	transform: $property;
}

@mixin transition($property) {
	-webkit-transition: $property;
	-moz-transition: $property;
	-ms-transition: $property;
	-o-transition: $property;
	transition: $property;
}

@mixin user-select($property) {
	-webkit-touch-callout: $property; /* iOS Safari */
	-webkit-user-select: $property; /* Safari */
	-khtml-user-select: $property; /* Konqueror HTML */
	-moz-user-select: $property; /* Old versions of Firefox */
	-ms-user-select: $property; /* Internet Explorer/Edge */
	user-select: $property; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin box-shadow($property) {
	-webkit-box-shadow: $property;
	-moz-box-shadow: $property;
	box-shadow: $property;
}

@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}
	@-o-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
}

@mixin placeholderColor {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin truncateLines($lineCount) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $lineCount;
	-webkit-box-orient: vertical;
}

@mixin fontFeatureSettings($features) {
	-moz-font-feature-settings: $features;
	-ms-font-feature-settings: $features;
	-webkit-font-feature-settings: $features;
	font-feature-settings: $features;
}

@mixin grayscale($value) {
	-webkit-filter: grayscale($value); /* Safari 6.0 - 9.0 */
	filter: grayscale($value);
}

/*
 * Gradients
 */

@mixin linear-gradient($property) {
	/*background-image: -webkit-linear-gradient($property);
  background-image:    -moz-linear-gradient($property);
  background-image:     -ms-linear-gradient($property);
  background-image:      -o-linear-gradient($property);
  background-image:         linear-gradient($property);*/
	background: -webkit-linear-gradient($property);
	background: -moz-linear-gradient($property);
	background: -ms-linear-gradient($property);
	background: -o-linear-gradient($property);
	background: linear-gradient($property);
}

/*
 * Hover
 */

@mixin hover() {
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	}
}

/*
 * Hover and active
 */

@mixin hoverAndActive() {
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	}

	&.active {
		@content;
	}
}

/*
 * Text Shadow
 */

@mixin textShadow() {
	text-shadow: 0 .1rem .1rem rgba(138, 57, 0, .2);
}

/*
 * Slide-up/down animations
 */

@mixin slideUp() {
	top: 1rem !important;
	opacity: 1 !important;
	animation: slide-up $animTimingTwo forwards;
	@keyframes slide-up {
		0% {
			top: 1rem;
			opacity: 1;
		}

		100% {
			top: -6rem;
			opacity: 0;
		}
	}
}
