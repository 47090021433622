#three-steps {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include minMobileLarge {
    margin-top: 10rem;
  }
  @include minTablet {
    margin-top: 15.6rem;
  }
  @include minDesktopSmall {
    margin-top: 18.8rem;
  }
  @include minDesktopLarge {
    margin-top: 23rem;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4rem;
    color: $erieBlack;
    font-weight: 600;
    text-align: center;
    @include minTablet {
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
    @include minDesktopSmall {
      max-width: 60.3rem;
    }
    @include minDesktopLarge {
      font-size: 5.2rem;
      line-height: 6rem;
    }
    @include minDesktopWide {
      line-height: 6.8rem;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    width: 100%;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8rem;
      position: relative;
      color: $erieBlack;
      @include minTablet {
        margin-top: 18rem;
        flex-direction: row;
      }
      @include minDesktopSmall {
        margin-top: 15.7rem;
      }
      @include minDesktopLarge {
        margin-top: 14rem;
      }

      .number {
        font-size: 4rem;
        color: $gains;
        font-weight: 700;
        @include minTablet {
          display: none;
        }
      }

      img {
        width: 29.6rem;
        height: auto;
        margin-top: 1.8rem;
        @include minTablet {
          width: auto;
          height: 38rem;
          margin-top: 0;
          margin-right: 8rem;
        }
        @include minDesktopSmall {
          height: 48.6rem;
          margin-right: 10rem;
        }
        @include minDesktopLarge {
          margin-right: 24rem;
        }
      }

      .text {
        width: 100%;
        max-width: 28.4rem;
        @include minDesktopSmall {
          max-width: 35rem;
        }

        .number {
          display: none;
          @include minTablet {
            font-size: 5.6rem;
            display: initial;
          }
          @include minDesktopLarge {
            font-size: 6.4rem;
          }
        }
      }

      h3 {
        font-size: 2rem;
        line-height: 3.6rem;
        margin-top: 2.8rem;
        text-align: center;
        @include minTablet {
          font-size: 2.4rem;
          line-height: 3rem;
          margin-top: 3.2rem;
          text-align: left;
        }
        @include minDesktopSmall {
          font-size: 2.8rem;
        }
      }

      p, li {
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 300;
        text-align: center;
        width: 100%;
        margin-top: 1.2rem;
        @include minTablet {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
          text-align: left;
        }
        @include minDesktopSmall {
          font-size: 1.8rem;
          line-height: 3rem;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        max-width: 26.8rem;
        list-style-position: inside;
        @include minTablet {
          max-width: 30.7rem;
          margin-top: 1.6rem;
        }
        @include minDesktopSmall {
          max-width: 100%;
        }
      }

      li {
        text-align: left;
        margin-top: .8rem;
        margin-left: 1.4rem;
        @include minTablet {
          margin-top: 1.2rem;
        }
        @include minDesktopLarge {
          margin-left: 2rem;
        }

        &:before {
          content: "\2022"; /* \2022 is the CSS unicode for a bullet */
          color: $gains;
          font-size: 1.6rem;
          font-weight: bold;
          display: inline-block;
          width: 1.4rem;
          margin-left: -1.4rem;
          @include minTablet {
            font-size: 1.8rem;
          }
          @include minDesktopLarge {
            font-size: 2rem;
            width: 2rem;
            margin-left: -2rem;
          }
        }
      }

      &:nth-child(2) {
        @include minTablet {
          flex-direction: row-reverse;
        }
        @include minDesktopSmall {
          padding-left: 4.8rem;
        }
        @include minDesktopLarge {
          padding-left: 9.7rem;
        }

        img {
          @include minTablet {
            margin-left: 3rem;
            margin-right: 0;
          }
          @include minDesktopSmall {
            margin-left: 5.6rem;
          }
          @include minDesktopLarge {
            margin-left: 12.4rem;
          }
        }

        .text {
          max-width: 26.4rem;
          @include minTablet {
            max-width: 30.7rem;
          }
          @include minDesktopLarge {
            max-width: 46rem;
          }
        }
      }

      &:first-of-type {
        margin-top: 3.8rem;
        @include minMobileLarge {
          margin-top: 5rem;
        }
        @include minTablet {
          margin-top: 9rem;
        }
        @include minDesktopLarge {
          margin-top: 14rem;
        }
      }
    }
  }
}