#how-it-works {
  margin-top: 4.8rem;
  @include minTablet {
    display: flex;
    align-items: center;
    margin-top: 10rem;
  }
  @include minDesktopWide {
    margin-top: 14rem;
  }

  .how-left {
    padding: 0 .8rem;
    @include minTablet {
      padding:0;
      margin-left: 1.6rem;
      width: 100%;
      max-width: 27.6rem;
    }
    @include minDesktopSmall {
      margin-left: 3.6rem;
      max-width: 34.3rem;
    }
    @include minDesktopLarge {
      margin-left: 6.4rem;
      max-width: 37rem;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      max-width: 22.4rem;
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
        max-width: 100%;
        font-weight: 700;
      }
      @include minDesktopLarge {
        max-width:28.7rem;
        font-size: 5.2rem;
        line-height: 6.8rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 2.4rem;
      font-weight: 300;
      max-width: 28rem;
      @include minMobileLarge {
        max-width: 100%;
      }
      @include minTablet {
        font-size: 1.6rem;
        line-height: 2.8rem;
        margin-top: 5.6rem;
      }
      @include minDesktopSmall {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-top: 4.8rem;
      }
    }

    .p-bold {
      font-weight: 500;
      @include minTablet {
        margin-top: 4.8rem;
      }
    }
  }

  .how-right {
    display: flex;
    flex-direction: column;
    padding: 0 .4rem;
    @include minTablet {
      padding:0;
      margin-left:auto;
      width: 100%;
      max-width: 39rem;
    }
    @include minDesktopSmall {
      max-width: 49.3rem;
    }
    @include minDesktopLarge {
      max-width: 49.6rem;
    }

    .how-step {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
      border-radius: 1.4rem;
      padding: 2rem 1rem 2rem 2rem;
      box-shadow: 0 1.1rem 2.5rem 0 rgba($silver, .2);
      border: .1rem solid $ghostWhite;
      color: $erieBlack;
      min-height: 10rem;
      position: relative;
      @include minMobileLarge {
        max-width: 29rem;
      }
      @include minTablet {
        max-width: 34rem;
        margin-top: 4rem;
        min-height: 12rem;
        padding:2.4rem 1.8rem 2.4rem 2.4rem;
      }
      @include minDesktopSmall {
        max-width: 42.4rem;
        padding:4rem 3.2rem;
        min-height: 13.6rem;
      }
      @include minDesktopLarge {
        padding:3rem 3.2rem;
      }

      &:first-of-type {
        margin-top: 2.4rem;
        @include minMobileLarge {
          margin-top: 4.8rem;
        }
        @include minTablet {
          margin-top: 0;
        }
      }

      &:nth-child(2n) {
        @include minMobileLarge {
          margin-left: auto;
        }
        @include minTablet {
          margin-left: initial;
        }
      }

      &:nth-child(2),&:nth-child(3) {
        @include minTablet {
          margin-left: 5rem;
        }
      }

      .icons8-dots2 {
        display: none;
        @include minTablet {
          display: initial;
          position:absolute;
          font-size: 2rem;
          top:-1rem;
          right:1.2rem;
          opacity: .7;
        }
      }
      .icons8-dots3 {
        display: none;
        @include minTablet {
          display: initial;
          position:absolute;
          font-size: 2rem;
          bottom:-1rem;
          right:1.6rem;
        }
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-weight: 700;
        margin-right: 1rem;
        min-width: 6.4rem;
        color:$gains;
        @include minMobileLarge {
          margin-right: 2rem;
        }
        @include minTablet {
          font-size: 4rem;
          margin-right: 2.4rem;
        }
        @include minDesktopSmall {
          font-size: 4.8rem;
          margin-right: 3.2rem;
        }
        @include minDesktopLarge {
          font-size:5.6rem;
          margin-right: 3rem;
        }
      }

      img {
        height: 3.6rem;
        margin-right: 2rem;
        @include minTablet {
          height: 4.3rem;
        }
        @include minDesktopLarge {
          height: 5rem;
          margin-right: 3.2rem;
        }
      }

      h3 {
        font-size: 1.6rem;
        font-weight: 600;
        @include minTablet {
          font-size: 1.8rem;
        }
      }

      p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 300;
        margin-top: .4rem;
        @include minTablet {
          font-size: 1.4rem;
          line-height: 2rem;
          margin-top: .6rem;
        }
        @include minDesktopLarge {
          margin-top: .8rem;
        }
      }
    }
  }
}