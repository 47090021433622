#subscribe {
  background: $erieBlack;
  border-radius: 2.4rem;
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
  position: relative;
  overflow: hidden;
  @include minMobileLarge {
    max-width: 35.2rem;
  }
  @include minTablet {
    margin-bottom: 8rem;
    margin-top: 12rem;
    max-width: 72.8rem;
    padding: 6.4rem 2.4rem;
  }
  @include minDesktopSmall {
    max-width: 96rem;
  }
  @include minDesktopLarge {
    max-width: 123.6rem;
  }

  .subscribe-bg {
    background: url("/images/illustrations/subscribe-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include minTablet {
      background: url("/images/illustrations/subscribe-tablet.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
    @include minDesktopSmall {
      background: url("/images/illustrations/subscribe-desktop-small.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 103%;
    }
    @include minDesktopLarge {
      background: url("/images/illustrations/subscribe-desktop-large.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }

  h2 {
    font-size: 3.2rem;
    margin-bottom: 0;
    line-height: 4rem;
    color: $white;
    font-weight: 600;
    margin-top: 3rem;
    text-align: center;
    z-index: 5;
    @include minMobileLarge {
      max-width: 27.5rem;
    }
    @include minTablet {
      font-size: 2.4rem;
      line-height: 3rem;
      max-width: 43rem;
    }
    @include minDesktopLarge {
      font-size: 2.8rem;
      line-height: 4rem;
    }
  }

  form {
    margin-top: 6.4rem;
    z-index: 5;
    @include minTablet {
      width: 50rem;
      padding:1.2rem;
    }

    .btn {
      @include minTablet {
        height: 6rem;
        min-width: 16rem;
        font-size: 2rem;
        border-radius: .8rem;
      }
    }
    input {
      @include minTablet {
        font-size: 1.8rem;
        line-height: 3rem;
        max-width: 55%;
      }
    }
  }
}
