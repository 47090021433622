#header {
  width: 100%;
  background: transparent;
  max-width: calc(100% - 4.8rem);
  margin: 1.2rem auto 0;

  @include minTablet {
    margin-top: 2.4rem;
  }
  @include minDesktopSmall {
    margin-top: 2.8rem;
    max-width: calc(100% - 6.4rem);
  }
  @include minDesktopLarge {
    max-width: 116.6rem;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .language-switcher {
      transition: all $animTimingTwo;
      position: relative;
      background: $white;
      cursor: pointer;
      padding: 1rem;
      width: max-content;

      &:hover {
        border-radius: .2rem .2rem 0 0;
        background: $isabelline;
      }

      .flag,
      .text {
        margin-right: .4rem;
      }

      .text {
        letter-spacing: .05rem;
        font-size: 1.5rem;
        line-height: 2.2rem;
        font-weight: 700;
        color: $erieBlack;
      }

      .switcher-btn {
        text-decoration: none;
        flex-direction: row;
        align-items: center;
        height: 100%;
        line-height: 2.2rem;
        display: flex;
        justify-content: center;

        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }

        .arrow {
          font-size: .3rem;
          transition: all $animTimingTwo;
          margin-left: auto;
          color: $erieBlack;
        }

      }

      .switcher-content {
        background-color: $isabelline;
        border-radius: 0 0 .2rem .2rem;
        transition: all $animTimingTwo;
        visibility: hidden;
        position: absolute;
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        opacity: 0;
        z-index: 1;
        top: 80%;
        right: 0;
        left: 0;

        .lang {
          transition: all $animTimingTwo;
          text-decoration: none;
          align-items: center;
          color: $erieBlack;
          display: flex;
          padding: 1rem;

          &:hover {
            background: darken($isabelline, 3%);
          }

        }

      }

      &.active .switcher-content,
      &:hover .switcher-content {
        flex-direction: column;
        visibility: visible;
        display: flex;
        opacity: 1;
        top: 100%;
      }

      &.active {
        border-radius: .2rem .2rem 0 0;
        background: $isabelline;
      }

      &.active .switcher-btn .arrow,
      &:hover .switcher-btn .arrow {
        transform: rotate(-90deg);
      }

      @include minTablet {
        margin-right: 3.6rem;
        margin-left: auto;
        margin-bottom: 0;
      }
      @include minDesktopSmall {
        margin-right: 4.8rem;
      }

    }

    .logo {
      display: flex;
      height: 2.5rem;
      @include minTablet {
        height: 3.5rem;
      }
      @include minDesktopSmall {
        height: 4.4rem;
      }
    }

    .menu {
      display: none;
      @include minTablet {
        display: flex;
      }

      .btn {
        height: 4rem;
        width: max-content;
        font-size: 1.5rem;
        line-height: 2.2rem;
      }

      .border-btn {
        padding: 0 2rem;
      }

      .simple-btn {
        display: none;
        @include minTablet {
          display: flex;
          margin-right: 3.6rem;
        }
        @include minDesktopSmall {
          margin-right: 4.8rem;
        }
      }
    }

  }
}
