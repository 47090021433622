html {
  scroll-behavior: smooth;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;

  * {
    box-sizing: border-box;
  }

  body {
    font-family: $poppins;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1,
    h2 {
      font-weight: 700;
    }

  }

}
