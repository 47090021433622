.btn {
  line-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.4rem;
  background: $majorelle;
  color: $white;
  border-radius: .4rem;
  text-decoration: none;
  width: max-content;
  cursor: pointer;
  transition: all $animTimingTwo;
  position: relative;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .1rem;
  outline:none;
  @include minDesktopSmall {
    padding: 0 4.8rem;
    line-height: 6rem;
    font-size: 2rem;
  }

  &:hover, &:focus {
    background: darken($majorelle,3%);
  }

  &.btn-loader {
    background: $majorelle;
    color: transparent;
    cursor: default;

    &:focus {
      box-shadow: none;
    }

  }

  img {
    transition: all 200ms;
  }

  .spinner {
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
    transition: opacity $animTimingOne;
    background-color: $white;
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    height: 4.8rem;
    width: 4.8rem;
    margin: auto;
    opacity: 0;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &.btn-loader .spinner {
    opacity: 1;
  }

  &.btn-loader img {
    opacity: 0;
  }

}

.simple-btn {
  box-shadow: none;
  background:transparent;
  color:$erieBlack;
  padding:0;
  margin-right: 4.8rem;
  &:hover {
    background: transparent;
  }
}
.border-btn {
  color:$erieBlack;
  background: $white;
  border:.1rem solid $gains;
  box-shadow: none;
  &:hover {
    color:$white;
    background: $erieBlack;
    border-color: transparent;
  }
}
