#about {
  margin-top: 5.7rem;
  padding: 0 .8rem;
  @include minMobileLarge {
    margin-top: 10rem;
  }
  @include minTablet {
    padding: 0;
    margin-top: 14rem;
  }
  @include minDesktopSmall {
    margin-top: 19rem;
  }
  @include minDesktopLarge {
    margin-top: 24rem;
  }

  .maxWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include minDesktopSmall {
      max-width: 92.5rem;
      flex-direction: row;
    }
    @include minDesktopLarge {
      max-width: 113.6rem;
    }
    @include minDesktopWide {
      margin-left: 12rem;
    }
  }

  .text {
    color: $erieBlack;
    width: 100%;
    @include minTablet {
      max-width: 65.8rem;
    }
    @include minDesktopSmall {
      max-width: 42.6rem;
      margin-right:5.5rem;
    }
    @include minDesktopLarge {
      max-width: 53.8rem;
      margin-right: 0;
    }

    .about-paragraphs {
      @include minDesktopSmall {
        padding-left: 3.2rem;
        border-left: .8rem solid $ghostWhite;
        border-radius: .2rem;
      }
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 700;
      @include minMobileLarge {
        text-align: center;
      }
      @include minTablet {
        font-size: 4.8rem;
        line-height: 5.6rem;
      }
      @include minDesktopSmall {
        max-width: 100%;
        text-align: left;
      }
      @include minDesktopLarge {
        max-width: 52.8rem;
      }
      @include minDesktopWide {
        font-size: 5.2rem;
        line-height: 6.8rem;
        max-width: 53.8rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 3.2rem;
      font-weight: 300;
      @include minMobileLarge {
        text-align: center;
      }
      @include minTablet {
        margin-top: 4.8rem;
        font-size: 1.6rem;
        line-height: 2.8rem;
        max-width: 62.8rem;
      }
      @include minDesktopSmall {
        margin-top: 6.4rem;
        font-size: 1.8rem;
        line-height: 3rem;
        text-align: left;
        max-width: 37.8rem;
      }
      @include minDesktopLarge {
        max-width: 43.5rem;
        margin-top: 8rem;
      }
    }
    .subtitle {
      font-weight: 500;
      @include minDesktopSmall {
        margin-top: 4rem;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    @include minMobileLarge {
      margin-top: 2.4rem;
    }
    @include minTablet {
      margin-top: 4.8rem;
      max-width: 44.6rem;
    }
    @include minDesktopSmall {
      margin-top: 0;
    }
    @include minDesktopLarge {
      max-width: 50rem;
    }
  }
}
