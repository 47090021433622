.legal-page {

  #legal-content {
    margin-top: 5.6rem;
    width: 100%;
    position: relative;
    padding-bottom: 8rem;

    @include minTablet {
      margin-top: 8rem;
    }

    @include minDesktopLarge {
      padding-bottom: 10rem;
    }

    @include minDesktopWide {
      padding-bottom: 20rem;
    }

    .right-circle {
      position: absolute;
      z-index: -1;
      display: none;
      @include minTablet {
        display: initial;
        right: -40rem;
        bottom: 0;
        width: auto;
        height: 46rem;
      }
      @include minDesktopSmall {
        height: 57rem;
      }
      @include minDesktopLarge {
        bottom: 32rem;
        height: 59rem;
      }
      @include minDesktopWide {
        bottom: 7rem;
        right: -20rem;
        height: 67rem;
      }
      @include minDesktopExtraWide {
        right: -5rem;
      }
    }

    .left-circle {
      position: absolute;
      display: none;
      z-index: -1;
      @include minDesktopLarge {
        display: initial;
        width: auto;
        left: -35rem;
        bottom: 64rem;
        height: 40.8rem;
      }
      @include minDesktopWide {
        left: -20rem;
        bottom: 35rem;
        height: 58rem;
      }
      @include minDesktopExtraWide {
        left: 0;
        bottom: 55rem;
      }
    }

    .inner {
      padding: 0;
      width:100%;
      margin: 0 auto;
      max-width: 28.8rem;
      @include minMobileLarge {
        max-width: 32rem;
      }
      @include minTablet {
        max-width: 64.8rem;
      }
      @include minDesktopSmall {
        max-width: 80rem;
      }
    }

    h1,
    h2, h3 {
      color: $erieBlack;
    }

    p, li {
      color: $erieBlack;
    }

    h1,
    h2,
    h3 {
      text-align: left;
    }

    h1 {
      margin-bottom: 1.6rem;
      line-height: 4.4rem;
      font-size: 3.6rem;
      letter-spacing: -.085;
      @include minMobileLarge {
        font-size: 4.4rem;
        line-height: 5.2rem;
        margin-bottom: 2.4rem;
      }
      @include minTablet {
        font-size: 5rem;
        line-height: 6rem;
      }
      @include minDesktopLarge {
        font-size: 5.6rem;
        line-height: 6.4rem;
      }
    }

    h2 {
      margin-bottom: 1rem;
      line-height: 4rem;
      margin-top: 6.4rem;
      font-size: 2.8rem;
      letter-spacing: -.1rem;

      @include minMobileLarge {
        font-size: 3.2rem;
        line-height: 4.4rem;
        margin-bottom: 1.6rem;
      }

      @include minTablet {
        font-size: 3.6rem;
        line-height: 5.2rem;
        margin-bottom: .8rem;
        margin-top: 8rem;
      }
      @include minDesktopLarge {
        margin-top: 12rem;
        font-size: 4rem;
        margin-bottom: 1.6rem;
      }
    }

    h3 {
      margin-bottom: .8rem;
      line-height: 2.4rem;
      margin-top: 6.4rem;
      font-size: 2.4rem;
      @include minMobileLarge {
        font-size: 2.8rem;
        line-height: 4rem;
        margin-bottom: 1.6rem;
      }
      @include minTablet {
        margin-bottom: .8rem;
        margin-top: 8rem;
      }
      @include minDesktopLarge {
        font-size: 3.2rem;
        line-height: 4.4rem;
        margin-top: 12rem;
        margin-bottom: 1.6rem;
      }
    }

    ol,
    ul,
    p,
    a {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 2.6rem;
      font-weight: 500;
      @include minMobileLarge {
        font-size: 1.8rem;
        line-height: 3rem;
      }

      @include minTablet {
        line-height: 3.2rem;
        font-size: 2rem;
        margin-bottom: 2.4rem;
      }
    }

    li {
      margin-bottom: .8rem;
    }

    ol,
    ul {
      padding-left: 1.8rem;
      @include minMobileLarge {
        padding-left: 2.2rem;
      }
    }

    a {
      color: $erieBlack;
      &:hover {
        text-decoration: none;
      }
    }

  }
}
